import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {CSSTransition} from 'react-transition-group';
import {bindActionCreators} from 'redux';
import {useSwipeable, EventData as SwipeEventData} from 'react-swipeable';

import {styled} from '../../theming/ThemeProvider';
import {Layout} from '../../components/Layout/Layout';
import {GameModel} from './Models/game';
import {waitForMs} from '../../../utils/promise/wait';
import {ListGameHomeButton} from './ListGameHomeButton';
import {ListGameArrowButton} from './ListGameArrowButton';
import {backgroundUpdateColorActionCreator} from '../Back/Data/background.actions';

const games: GameModel[] = [
    {name: `abeilles`, icon: '01 btn'},
    {name: `chaussetes`, icon: '02 btn'},
    {name: `2chats`, icon: '03 btn'},
    {name: `poires`, icon: '04 btn'},
    {name: `3cubes`, icon: '05 btn'},
    {name: `cerises`, icon: '06 btn'},
    {name: `ours`, icon: '07 btn'},
    {name: `feuilles`, icon: '08 btn'},
    {name: `maison-fourmi`, icon: '09 btn'},
    {name: `lampe`, icon: '10 btn'},
    {name: `confitures`, icon: '11 btn'},
    {name: `bateau`, icon: '12 btn'},
    {name: `bb`, icon: '13 btn'},
    {name: `crabe`, icon: '14 btn'},
    {name: `scarabe`, icon: '15 btn'},
    {name: `sac`, icon: '16 btn'},
    {name: `aubergines`, icon: '17 btn'},
    {name: `clown`, icon: '18 btn'},
    {name: `portrait-nb`, icon: '19 btn'},
    {name: `2ours`, icon: '20 btn'},
    {name: `ecrire-A`, icon: '21 btn'},
    {name: `3angles`, icon: '22 btn'},
    {name: `3animaux`, icon: '23 btn'},
    {name: `crane`, icon: '24 btn'},
    {name: `ba`, icon: '25 btn'},
    {name: `fusee`, icon: '26 btn'},
    {name: `perles`, icon: '27 btn'},
    {name: `pomme`, icon: '28 btn'},
    {name: `heure`, icon: '29 btn'},
    {name: `coccinelle-abc`, icon: '30 btn'},
    {name: `piaf-abc`, icon: '31 btn'},
    {name: `montgolfiere`, icon: '32 btn'},
    {name: `bille`, icon: '33 btn'},
    {name: `sac-3formes`, icon: '34 btn'},
    {name: `portrait`, icon: '35 btn'},
    {name: `arbre-envers`, icon: '36 btn'},
    {name: `monde`, icon: '37 btn'},
    {name: `321`, icon: '38 btn'},
    {name: `abm`, icon: '39 btn'},
    {name: `labyrinthe`, icon: '40 btn'},
    {name: `puzzle`, icon: '41 btn'},
    {name: `train`, icon: '42 btn'},
    {name: `3formes`, icon: '43 btn'},
    {name: `0044`, icon: '44 btn'},
    {name: `0045`, icon: '45 btn'},
    {name: `0046`, icon: '46 btn'},
];

const duration = 300;

const ListGameLayout = styled(Layout)`
    z-index: 1;

    flex: 1;
    margin: 3rem;
    transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
    align-items: center;
    flex-direction: column;
`;

const ListGameMosaicLayout = styled(Layout)`
    flex: 1;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`;

const ListGameMosaicRows = styled(Layout)<{direction: number}>`
    flex: 1;
    margin: 0 4rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: transform ${duration}ms cubic-bezier(0.29, 0.4, 0.36, 1), opacity ${duration}ms cubic-bezier(0.29, 0.4, 0.36, 1);

    &.entering {
        opacity: 0;
        transform: ${props => (props.direction === -1 ? 'translateX(15%)' : 'translateX(-15%)')};
    }
    &.exiting {
        opacity: 0;
        transform: ${props => (props.direction === -1 ? 'translateX(-15%)' : 'translateX(15%)')};
    }
    &.exited {
        transform: translateX(0);
    }
`;

const ListGameMosaicRow = styled(Layout)`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-direction: row;
    justify-content: center;

    &:last-child {
        margin-bottom: 0;
    }
`;

const ListGameMosaicItem = styled.img`
    width: 128px;
    cursor: pointer;
    height: auto;
    user-select: none;
    margin-right: 10px;
    margin-left: 10px;

    & > div:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 568px) {
        width: 62px;
    }
    @media (min-width: 568px) and (max-width: 732px) {
        width: 68px;
    }
    @media (min-width: 732px) and (max-width: 1000px) {
        width: 76px;
    }
`;

const fmod = (a: number, b: number) => Number((a - Math.floor(a / b) * b).toPrecision(8));
const isMobile = window.innerWidth <= 732 || (window.innerWidth <= 812 && window.innerHeight <= 414);
const totalRows = isMobile ? [0, 1] : [0, 1, 2];
const itemsPerRow = 4;
const itemsPerPage = isMobile ? 8 : 12;
const maxNumberPages = Math.ceil(games.length / itemsPerPage);

export const ListGame: React.FC = () => {
    const [page, setPage] = React.useState(parseInt(localStorage.getItem('page') || '0', 10));
    const [direction, setDirection] = React.useState(0);
    const [lastDirection, setLastDirection] = React.useState(0);

    const goPage = React.useCallback(async (newPage: number, newDirection: number) => {
        setDirection(newDirection);
        setLastDirection(newDirection);
        await waitForMs(duration);
        setPage(newPage);
        setDirection(0);
    }, []);
    const nextPage = React.useCallback(() => goPage(fmod(page + 1, maxNumberPages), 1), [page, goPage]);
    const prevPage = React.useCallback(() => goPage(fmod(page - 1, maxNumberPages), -1), [page, goPage]);
    const swipeHandler = React.useCallback(
        (eventData: SwipeEventData) => {
            if (eventData.dir === 'Left') {
                nextPage();
            } else if (eventData.dir === 'Right') {
                prevPage();
            }
        },
        [nextPage, prevPage],
    );

    const dispatch = useDispatch();
    const updateBackgroundColor = bindActionCreators(backgroundUpdateColorActionCreator, dispatch);

    const swipeHandlers = useSwipeable({onSwiped: swipeHandler});

    React.useEffect(() => {
        localStorage.setItem('page', page.toString());
        updateBackgroundColor(page);
    }, [page, updateBackgroundColor]);

    return (
        <ListGameLayout className={ListGameClassName}>
            <ListGameHomeButton to="/" />
            <ListGameMosaicLayout {...swipeHandlers}>
                <ListGameArrowButton left={true} onClick={prevPage} />
                <CSSTransition in={direction !== 0} classNames="" timeout={duration}>
                    {status => (
                        <ListGameMosaicRows className={status} direction={lastDirection}>
                            {totalRows.map(row => (
                                <ListGameMosaicRow key={row}>
                                    {games.slice(page * itemsPerPage + row * itemsPerRow, page * itemsPerPage + (row + 1) * itemsPerRow).map(game => (
                                        <Link key={game.name} to={`/game/${1 + games.indexOf(game)}`}>
                                            <ListGameMosaicItem src={require(`./Files/GameButtons/${game.icon}.svg`)} />
                                        </Link>
                                    ))}
                                </ListGameMosaicRow>
                            ))}
                        </ListGameMosaicRows>
                    )}
                </CSSTransition>
                <ListGameArrowButton left={false} onClick={nextPage} />
            </ListGameMosaicLayout>
        </ListGameLayout>
    );
};
export const ListGameClassName = 'list-game';
