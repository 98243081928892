import React from 'react';

export const useVisibility = () => {
    const [visible, setVisible] = React.useState(document.visibilityState === 'visible');
    const visibilityHandler = React.useCallback(() => {
        setVisible(document.visibilityState === 'visible');
    }, []);
    React.useEffect(() => {
        document.addEventListener('visibilitychange', visibilityHandler);
        return () => {
            document.removeEventListener('visibilitychange', visibilityHandler);
        };
    }, [visibilityHandler]);
    return visible;
};
