import React from 'react';
import {Link} from 'react-router-dom';

import {styled} from '../../theming/ThemeProvider';

const HomeButton = styled(Link)`
    cursor: pointer;
    opacity: 0;
    animation: appear 0.4s 0.3s cubic-bezier(0.29, 0.4, 0.36, 1) forwards;
    transition: transform 0.2s cubic-bezier(0.29, 0.4, 0.36, 1);

    &:active {
        transform: scale(1.1);

        & circle {
            fill: ${props => props.theme.color.red};
            stroke: ${props => props.theme.color.red};
            transition: fill stroke 0.2s cubic-bezier(0.29, 0.4, 0.36, 1);
        }
    }

    @media only screen and (max-height: 640px) {
        & svg {
            transform: translateY(-15px);
            transform-origin: 0 0;
        }
    }

    @media (minwidth: 480px) and (maxwidth: 720px) {
        width: 36px;
        height: 36px;
        & svg {
            transform: translateY(-20px) scale(0.75);
            transform-origin: 0 0;
        }
        margin-bottom: 10px;
    }
`;

export const ListGameHomeButton: React.FC<{to: string}> = ({to}) => {
    return (
        <HomeButton to={to}>
            <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <circle strokeOpacity=".7" stroke="#FFF" strokeWidth="2" cx="24" cy="24" r="23" />
                    <path d="M22.1837 33.6837v3.0612M24.2245 33.6837v3.0612" stroke="#FFF" strokeWidth="2" opacity=".76" strokeLinecap="square" />
                    <path
                        d="M31.8776 24.0108l.0024.0015a13.5342 13.5342 0 0 1-.0024.0366v.452a4.316 4.316 0 0 1-.3219 1.6405c-1.2087 4.926-5.2612 7.2872-8.3664 7.2872C19.666 33.4286 13 30.6898 13 30.6898l10.1893-10.7557.0148.004v-3.6022c0-2.3947 1.9465-4.3359 4.3367-4.3359 1.4334 0 2.7043.6976 3.4939 1.7697l3.2322 2.6742-2.3893.937v6.6299z"
                        fill="#FFF"
                    />
                </g>
            </svg>
        </HomeButton>
    );
};
