import React from 'react';

import {styled} from '../../theming/ThemeProvider';
import {padStart} from '../../../utils/string/padStart';
import {OpenGameBackButton} from './OpenGameBackButton';

const OpenGameLayout = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const OpenGameFrame = styled.iframe`
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
`;

interface OpenGameProps {
    uid: string;
}

export const OpenGame: React.FC<OpenGameProps> = ({uid}) => {
    const gameName = React.useMemo(() => padStart(uid.toString(), 4, '0'), [uid]);
    return (
        <OpenGameLayout>
            <OpenGameBackButton />
            <OpenGameFrame src={`games/${gameName}/index.html`} scrolling="no" />
        </OpenGameLayout>
    );
};
