import React from 'react';

import {styled} from '../../theming/ThemeProvider';

const leftArrowSvg = (
    <svg width="24" height="42" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M.9601 20.6224a2.4913 2.4913 0 0 1 .734-1.7559L18.8805 1.6803c.9756-.9756 2.5492-.9837 3.53-.003.9739.974.9765 2.5504-.0031 3.53L6.3417 21.2728l15.9029 15.903c.8884.8883.897 2.32.0054 3.2116-.8854.8854-2.3163.89-3.2117-.0054L1.5363 22.88A2.2715 2.2715 0 0 1 .96 20.6223z"
            fill="#FFF"
            fillRule="evenodd"
        />
    </svg>
);
const rightArrowSvg = (
    <svg width="24" height="42" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.0433 20.6237a2.4912 2.4912 0 0 0-.734-1.7566L5.1222 1.6801C4.1465.7044 2.5727.6964 1.5918 1.6773c-.9742.9742-.977 2.5508.0027 3.5305l16.0662 16.0661L1.7572 37.1773c-.8884.8884-.8969 2.3204-.0051 3.2122.8856.8855 2.3167.8902 3.2121-.0052l17.503-17.503a2.2716 2.2716 0 0 0 .576-2.2576z"
            fill="#FFF"
            fillRule="evenodd"
        />
    </svg>
);

const Arrow = styled.a`
    cursor: pointer;
    opacity: 0;
    animation: fade-in 0.4s 0.3s cubic-bezier(0.29, 0.4, 0.36, 1) forwards;

    & path {
        transition: fill 0.2s ease;
    }
    &:active path {
        fill: ${props => props.theme.color.red};
    }
`;

export const ListGameArrowButton: React.FC<{left: boolean; onClick: () => void}> = ({left, onClick}) => {
    return <Arrow onClick={onClick}>{left ? leftArrowSvg : rightArrowSvg}</Arrow>;
};
