import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {Provider as ReduxProvider} from 'react-redux';

import {AppRouter} from './AppRouter';
import {defaultTheme} from './theming/Theme';
import {ThemeProvider} from './theming/ThemeProvider';
import {store, history} from './AppState';
import {PortraitChecker} from './components/PortraitChecker/PortraitChecker';
import {BackgroundProvider} from './containers/Back/Provider/BackgroundProvider';

export const App: React.FC = () => {
    return (
        <ReduxProvider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={defaultTheme}>
                    <BackgroundProvider>
                        <PortraitChecker>
                            <AppRouter />
                        </PortraitChecker>
                    </BackgroundProvider>
                </ThemeProvider>
            </ConnectedRouter>
        </ReduxProvider>
    );
};
