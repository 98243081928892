import {createHashHistory} from 'history';
import {composeWithDevTools} from 'redux-devtools-extension';
import {connectRouter, routerMiddleware, RouterState} from 'connected-react-router';
import {createStore, applyMiddleware, combineReducers, Store} from 'redux';

import {backgroundReducer, BackgroundState} from './containers/Back/Data/background.reducer';

export interface AppState {
    router: RouterState;
    background: BackgroundState;
}

export const history = createHashHistory();

export const store: Store<AppState> = createStore(
    combineReducers({
        router: connectRouter(history),
        background: backgroundReducer,
    }),
    composeWithDevTools(applyMiddleware(routerMiddleware(history))),
);
