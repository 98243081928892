import {Action} from 'redux';

import {BackgroundColorModel} from '../Models/back';
import {backgroundUpdateColorAction, BackgroundUpdateColorAction} from './background.actions';

export interface BackgroundState {
    color: BackgroundColorModel;
}

const initialState: BackgroundState = {
    color: BackgroundColorModel.Default,
};

export const backgroundReducer = (state: BackgroundState = initialState, action: Action): BackgroundState => {
    switch (action.type) {
        case backgroundUpdateColorAction:
            const {color} = (action as BackgroundUpdateColorAction).payload;
            return {
                ...state,
                color,
            };
        default:
            return state;
    }
};
