import './Files/Theme.css';

export interface ThemeModel {
    color: {
        red: string;
        green: string;
        white: string;
        whiteTransparent: string;
        //
        backgroundRoi: string;
        backgroundCiel: string;
        backgroundDefault: string;
        backgroundPetunia: string;
        backgroundMexique: string;
        backgroundPrairie: string;
        backgroundTournesol: string;
    };
}

export const defaultTheme: ThemeModel = {
    color: {
        red: '#FF5656',
        green: '#14AD8B',
        white: '#FFFFFF',
        whiteTransparent: 'rgba(255, 255, 255, 0.7)',
        //
        backgroundRoi: '#297EE1',
        backgroundCiel: '#4BC1F0',
        backgroundDefault: '#533AAA',
        backgroundPetunia: '#B74FBC',
        backgroundMexique: '#EC6E42',
        backgroundPrairie: '#14AD8B',
        backgroundTournesol: '#FED143',
    },
};
