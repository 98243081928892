import {ThemeProvider} from 'emotion-theming';
import {Global, GlobalProps} from '@emotion/core';
import styled, {CreateStyled} from '@emotion/styled';

import {ThemeModel} from './Theme';

const themedStyled = styled as CreateStyled<ThemeModel>;
const themedGlobal = Global as React.ComponentType<GlobalProps<ThemeModel>>;
const themedProvider = ThemeProvider;

export {themedStyled as styled};
export {themedGlobal as Global};
export {themedProvider as ThemeProvider};
