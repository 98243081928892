import React from 'react';

import {styled} from '../../theming/ThemeProvider';

const QuitButton = styled.a`
    cursor: pointer;
    display: flex;
    animation: appear 0.4s cubic-bezier(0.29, 0.4, 0.36, 1);
    transition: transform 0.2s cubic-bezier(0.29, 0.4, 0.36, 1);
    align-items: center;
    justify-content: center;

    &:active {
        transform: scale(1.1);

        & circle {
            fill: ${props => props.theme.color.red};
            stroke: ${props => props.theme.color.red};
            transition: fill stroke 0.2s cubic-bezier(0.29, 0.4, 0.36, 1);
        }
    }
`;

interface ParentalControlQuitButtonProps {
    onClose: () => void;
}

export const ParentalControlQuitButton: React.FC<ParentalControlQuitButtonProps> = ({onClose}) => {
    return (
        <QuitButton onClick={onClose}>
            <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <circle strokeOpacity=".7" stroke="#FFF" strokeWidth="2" cx="24" cy="24" r="23" />
                    <g transform="translate(13 15)" fill="#FFF">
                        <path
                            d="M1.0895 8.8618a1.2614 1.2614 0 0 1 .3633-1.0223l7.536-7.536a1.2622 1.2622 0 0 1 1.7895.0013c.4945.4945.4947 1.296.0013 1.7894l-7.0428 7.043 6.963 6.9629c.448.448.4498 1.1725-.003 1.6252-.4495.4495-1.1795.4484-1.625.0028l-7.702-7.7018a1.1444 1.1444 0 0 1-.2803-1.1645z"
                            transform="matrix(-1 0 0 1 12.1835 0)"
                        />
                        <path d="M10.0895 8.8618a1.2614 1.2614 0 0 1 .3633-1.0223l7.536-7.536a1.2622 1.2622 0 0 1 1.7895.0013c.4945.4945.4947 1.296.0013 1.7894l-7.0428 7.043 6.963 6.9629c.448.448.4498 1.1725-.003 1.6252-.4495.4495-1.1795.4484-1.625.0028l-7.702-7.7018a1.1444 1.1444 0 0 1-.2803-1.1645z" />
                    </g>
                </g>
            </svg>
        </QuitButton>
    );
};
