import {Action} from 'redux';

import {BackgroundColorModel} from '../Models/back';

export const backgroundUpdateColorAction = '@@background/COLOR_UPDATE';

export interface BackgroundUpdateColorAction extends Action<typeof backgroundUpdateColorAction> {
    payload: {color: BackgroundColorModel};
}

export const backgroundUpdateColorActionCreator = (color: BackgroundColorModel): BackgroundUpdateColorAction => ({
    type: backgroundUpdateColorAction,
    payload: {color},
});
