import React from 'react';
import Anime from 'animejs';

export const useAudio = (src: string, autoplay: boolean = true): [HTMLAudioElement, () => void, () => void] => {
    const audio = React.useMemo(() => new Audio(src), [src]);
    const play = React.useCallback(async () => {
        try {
            await audio.play();
        } catch (e) {}
    }, [audio]);
    const pause = React.useCallback(async () => {
        try {
            await Anime({
                targets: [audio],
                easing: 'linear',
                volume: 0,
            }).finished;
            audio.pause();
        } catch (e) {}
    }, [audio]);
    React.useEffect(() => {
        if (autoplay) {
            play();
        }
    });
    return [audio, play, pause];
};
