import React from 'react';
import {useSelector} from 'react-redux';
import {InterpolationWithTheme} from '@emotion/core';

import trame from '../Files/trame.png';

import {ThemeModel} from '../../../theming/Theme';
import {styled, Global} from '../../../theming/ThemeProvider';
import {BackgroundColorModel} from '../Models/back';
import {backgroundColorSelector} from '../Data/background.selectors';

const TrameImage = styled.img`
    top: 50%;
    left: 50%;
    width: 70vw;
    position: fixed;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;

    @media (min-width: 720px) and (max-width: 1025px) {
        top: 50%;
        width: 110vw;
    }
    @media (min-width: 568px) and (max-width: 720px) {
        top: 40%;
        width: 200vw;
    }
    @media (max-width: 480px) {
        top: 40%;
        left: 42%;
        width: 200vw;
    }
`;

const getColor = (theme: ThemeModel, backgroundColor: BackgroundColorModel): string => {
    switch (backgroundColor) {
        case BackgroundColorModel.Roi:
            return theme.color.backgroundRoi;
        case BackgroundColorModel.Ciel:
            return theme.color.backgroundCiel;
        case BackgroundColorModel.Petunia:
            return theme.color.backgroundPetunia;
        case BackgroundColorModel.Mexique:
            return theme.color.backgroundMexique;
        case BackgroundColorModel.Prairie:
            return theme.color.backgroundPrairie;
        case BackgroundColorModel.Tournesol:
            return theme.color.backgroundTournesol;
        default:
            return theme.color.backgroundDefault;
    }
};

export const BackgroundProvider: React.FC = ({children}) => {
    const backgroundColor = useSelector(backgroundColorSelector);
    const backgroundStyle: InterpolationWithTheme<ThemeModel> = theme => ({
        '#root': {
            transition: 'background-color 0.3s cubic-bezier(0.29, 0.4, 0.36, 1)',
            backgroundColor: getColor(theme, backgroundColor),
        },
    });
    return (
        <>
            <Global styles={backgroundStyle} />
            <TrameImage src={trame} />
            {children}
        </>
    );
};
