import React from 'react';
import {useSelector} from 'react-redux';
import {Switch, Route} from 'react-router';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

import {Home} from './containers/Home/Home';
import {styled} from './theming/ThemeProvider';
import {AppState} from './AppState';
import {OpenGame} from './containers/OpenGame/OpenGame';
import {ListGame, ListGameClassName} from './containers/ListGame/ListGame';

const AppSection = styled.section`
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    position: fixed;
    transition: opacity 0.3s cubic-bezier(0.29, 0.4, 0.36, 1);
    justify-content: center;

    &.app-section-enter {
        opacity: 0;
        & .${ListGameClassName} {
            transform: translateY(150%);
        }
    }

    &.app-section-enter-done {
        & .${ListGameClassName} {
            transform: translateY(0);
        }
    }

    &.app-section-enter.app-section-enter-active {
        opacity: 1;
        & .${ListGameClassName} {
            transform: translateY(0);
        }
    }

    &.app-section-exit {
        opacity: 1;
    }

    &.app-section-exit.app-section-exit-active {
        opacity: 0;
    }
`;

export const AppRouter: React.FC = () => {
    const location = useSelector((state: AppState) => state.router.location);
    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.pathname} timeout={300} classNames="app-section">
                <AppSection>
                    <Switch location={location}>
                        <Route path="/" exact={true}>
                            <Home />
                        </Route>
                        <Route path="/games">
                            <ListGame />
                        </Route>
                        <Route path="/game/:uid">{route => <OpenGame uid={route!.match!.params.uid} />}</Route>
                    </Switch>
                </AppSection>
            </CSSTransition>
        </TransitionGroup>
    );
};
