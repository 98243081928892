import React from 'react';

export const useClientRect = <T extends HTMLElement>(): [ClientRect | null, (el: T | null) => void] => {
    const [element, setElement] = React.useState(window.document.body);
    const [clientRect, setClientRect] = React.useState(null as ClientRect | null);

    const ref = React.useCallback(element => {
        setElement(element);
    }, []);
    const resize = React.useCallback(() => {
        if (element) {
            setClientRect(element.getBoundingClientRect());
        }
    }, [element]);

    React.useLayoutEffect(() => {
        resize();
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [element, resize]);

    return [clientRect, ref];
};
