import React from 'react';
import {Link} from 'react-router-dom';

import {styled} from '../../theming/ThemeProvider';

const PlayButton = styled(Link)`
    cursor: pointer;
    animation: appear 0.5s cubic-bezier(0.29, 0.4, 0.36, 1), scale 4s cubic-bezier(0.29, 0.4, 0.36, 1) 0.5s infinite;
    border-radius: 100px;

    &:active {
        & rect {
            fill: ${props => props.theme.color.red};
            stroke: ${props => props.theme.color.red};
            transition: all 0.2s cubic-bezier(0.29, 0.4, 0.36, 1);
        }
        & path {
            fill: white;
            stroke: white;
            transition: all 0.2s cubic-bezier(0.29, 0.4, 0.36, 1);
        }
    }

    @keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(1);
        }
    }
`;

export const HomePlayButton: React.FC<{to: string}> = ({to}) => {
    return (
        <PlayButton to={to}>
            <svg width="80" height="48" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(-920 -833)">
                        <g transform="translate(733 189)">
                            <g transform="translate(187 644)">
                                <rect stroke="#FFF" strokeWidth="2" fill="#FFF" x="1" y="1" width="78" height="46" rx="23" />
                                <path
                                    d="M40.2778 12.2307L50.036 35H30.5195l9.7583-22.7693z"
                                    stroke="#FF5656"
                                    fill="#FF5656"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    transform="rotate(90 40.2778 24)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </PlayButton>
    );
};
