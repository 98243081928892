import React from 'react';
import {CSSTransition} from 'react-transition-group';
import {unstable_batchedUpdates} from 'react-dom';

import {styled} from '../../theming/ThemeProvider';
import {Layout} from '../Layout/Layout';
import {waitForMs} from '../../../utils/promise/wait';
import {ParentalControlKeyboard} from './ParentalControlKeyboard';
import {ParentalControlQuitButton} from './ParentalControlQuitButton';

const operations = [
    {operation: '12 x 3', result: 36},
    {operation: '13 + 9', result: 22},
    {operation: '7 x 6', result: 42},
    {operation: '45 - 6', result: 39},
    {operation: '3 x 9', result: 27},
    {operation: '27 ÷ 3', result: 9},
    {operation: '21 ÷ 7', result: 3},
    {operation: '7 x 11', result: 77},
    {operation: '5 x 10', result: 50},
    {operation: '90 ÷ 10', result: 9},
];
const randomOperation = () => operations[Math.floor(Math.random() * operations.length)];

const ParentalControlLayout = styled(Layout)`
    z-index: 1;

    top: 0;
    flex: 1;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 3rem;
    display: flex;
    position: fixed;
    transition: all 0.3s ease;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-height: 640px) {
        flex-direction: row;
        justify-content: space-between;
    }

    @media only screen and (min-height: 641px) {
        justify-content: initial;
    }

    &.fade-exit-active {
        opacity: 0;
    }
    &.fade-enter {
        opacity: 0;
    }
    &.fade-enter-active {
        opacity: 1;
    }
`;

const Operation = styled.span<{correct: boolean}>`
    color: ${props => (props.correct ? props.theme.color.green : props.theme.color.white)};
    opacity: 0;
    animation: appear 0.3s cubic-bezier(0.29, 0.4, 0.36, 1) 0.5s forwards;
    font-size: 8vh;

    &.shake-enter {
        color: ${props => props.theme.color.red};
        opacity: 1;
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
        backface-visibility: hidden;
    }

    @media only screen and (min-height: 641px) {
        margin-top: auto;
    }

    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }
        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }
        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }
        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }
`;

interface ParentalControlProps {
    onClose: () => void;
    onSuccess: () => void;
}

export const ParentalControl: React.FC<ParentalControlProps> = ({onClose, onSuccess}) => {
    const [operation, setOperation] = React.useState(randomOperation());
    const [operationNumber, setOperationNumber] = React.useState(0);
    const isOperationCorrect = operationNumber === operation.result;
    const isOperationIncorrect = operationNumber > 9 && operationNumber !== operation.result;

    const addNumber = React.useCallback((num: number) => {
        setOperationNumber(n => (n === 0 ? num : n * 10 + num));
    }, []);

    React.useEffect(() => {
        (async () => {
            if (isOperationIncorrect) {
                await waitForMs(800);
                unstable_batchedUpdates(() => {
                    setOperation(randomOperation());
                    setOperationNumber(0);
                });
            } else if (isOperationCorrect) {
                await waitForMs(800);
                onSuccess();
            }
        })();
    }, [onSuccess, isOperationCorrect, isOperationIncorrect]);

    return (
        <ParentalControlLayout>
            <ParentalControlQuitButton onClose={onClose} />
            <CSSTransition in={isOperationIncorrect} timeout={800} classNames="shake">
                <Operation correct={isOperationCorrect}>
                    {operation.operation} = {operationNumber || ''}
                </Operation>
            </CSSTransition>
            <ParentalControlKeyboard onKeyClick={addNumber} />
        </ParentalControlLayout>
    );
};
