import React from 'react';
import {Link} from 'react-router-dom';
import Lottie, {EventListener} from 'react-lottie';

import {Layout} from '../../components/Layout/Layout';
import {styled} from '../../theming/ThemeProvider';
import {useAudio} from '../../../utils/react/hooks/useAudio';
import {useVisibility} from '../../../utils/react/hooks/useVisibility';

import logoIn from './Files/animations/logo_in.json';
import logoLoop from './Files/animations/logo_loop.json';
import logoSound from './Files/logo_sound.mp3';

const lottieInAnimation = {
    loop: false,
    autoplay: true,
    animationData: logoIn,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const lottieLoopAnimation = {
    loop: true,
    autoplay: true,
    animationData: logoLoop,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const LottieLayout = styled(Layout)`
    margin: auto;
    max-width: 70%;

    @media only screen and (max-height: 640px) {
        margin: 0 auto;
        max-width: 90%;
    }
`;

const LottieLink = styled(Link)`
    all: inherit;
    cursor: pointer;
`;

export const HomeLogo: React.FC<{to: string}> = ({to}) => {
    const visible = useVisibility();
    const [, play, pause] = useAudio(logoSound, visible);
    const [looping, setLooping] = React.useState(false);
    const lottieEventListeners: EventListener[] = React.useMemo(() => [{eventName: 'complete', callback: () => setLooping(true)}], []);
    visible ? play() : pause();

    React.useEffect(() => {
        visible ? play() : pause();
        return () => pause();
    }, [play, pause, visible]);

    return (
        <LottieLayout>
            <LottieLink to={to}>
                <Lottie
                    speed={visible ? 1 : 0}
                    options={looping ? lottieLoopAnimation : lottieInAnimation}
                    eventListeners={lottieEventListeners}
                    isClickToPauseDisabled={true}
                />
            </LottieLink>
        </LottieLayout>
    );
};
