import React from 'react';

import {styled} from '../../theming/ThemeProvider';
import {Layout} from '../Layout/Layout';
import {ParentalControlKey} from './ParentalControlKey';

const KeyboardLayout = styled(Layout)`
    display: flex;
    animation: appear 0.4s cubic-bezier(0.29, 0.4, 0.36, 1);
    margin-top: 25px;
    margin-bottom: auto;
    flex-direction: column;
    & div {
        display: flex;
        flex-shrink: 0;
        margin-bottom: 13px;
        &:last-child {
            margin-bottom: 0;
            justify-content: center;
        }
    }
    & span {
        color: ${props => props.theme.color.white};
        width: 11vh;
        border: 2px solid ${props => props.theme.color.whiteTransparent};
        cursor: pointer;
        height: 11vh;
        display: flex;
        font-size: 7vh;
        transition: border-color background-color 0.1s cubic-bezier(0.29, 0.4, 0.36, 1);
        align-items: center;
        margin-right: 13px;
        border-radius: 100%;
        justify-content: center;
        &:last-child {
            margin-right: 0;
        }
        &:active {
            border-color: ${props => props.theme.color.red};
            background-color: ${props => props.theme.color.red};
        }
    }

    @media only screen and (max-width: 640px) {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

interface ParentalControlKeyboardProps {
    onKeyClick: (key: number) => void;
}

export const ParentalControlKeyboard: React.FC<ParentalControlKeyboardProps> = ({onKeyClick}) => {
    return (
        <KeyboardLayout>
            <div>
                <ParentalControlKey value={1} onClick={onKeyClick} />
                <ParentalControlKey value={2} onClick={onKeyClick} />
                <ParentalControlKey value={3} onClick={onKeyClick} />
            </div>
            <div>
                <ParentalControlKey value={4} onClick={onKeyClick} />
                <ParentalControlKey value={5} onClick={onKeyClick} />
                <ParentalControlKey value={6} onClick={onKeyClick} />
            </div>
            <div>
                <ParentalControlKey value={7} onClick={onKeyClick} />
                <ParentalControlKey value={8} onClick={onKeyClick} />
                <ParentalControlKey value={9} onClick={onKeyClick} />
            </div>
            <div>
                <ParentalControlKey value={0} onClick={onKeyClick} />
            </div>
        </KeyboardLayout>
    );
};
