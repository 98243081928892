import React from 'react';
import {useDispatch} from 'react-redux';
import {CSSTransition} from 'react-transition-group';
import {bindActionCreators} from 'redux';

import {styled} from '../../theming/ThemeProvider';
import {Layout} from '../../components/Layout/Layout';
import {HomeLogo} from './HomeLogo';
import {HomePlayButton} from './HomePlayButton';
import {ParentalControl} from '../../components/Parental/ParentalControl';
import {HomeWebsiteButton} from './HomeWebsiteButton';
import {BackgroundColorModel} from '../Back/Models/back';
import {backgroundUpdateColorActionCreator} from '../Back/Data/background.actions';

const HomeLayout = styled(Layout)`
    z-index: 1;

    flex: 1;
    margin: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    transition: all 0.3s ease;

    &.fade-exit-active {
        opacity: 0;
    }
    &.fade-enter {
        opacity: 0;
    }
    &.fade-enter-active {
        opacity: 1;
    }

    @media only screen and (max-height: 640px) {
        flex-direction: row;
        justify-content: space-around;
    }
`;

export const Home: React.FC = () => {
    const dispatch = useDispatch();
    const updateBackgroundColor = bindActionCreators(backgroundUpdateColorActionCreator, dispatch);
    const [parentalControl, setParentalControl] = React.useState(false);
    const openParentalControl = React.useCallback(() => setParentalControl(true), []);
    const closeParentalControl = React.useCallback(() => setParentalControl(false), []);
    const openWebsiteAndCloseParentalControl = React.useCallback(() => {
        const websitePopup = window.open('https://piupiupiu.net/parent', '_noblank');
        websitePopup && websitePopup.focus();
        setParentalControl(false);
    }, []);

    React.useEffect(() => {
        updateBackgroundColor(BackgroundColorModel.Default);
    }, [updateBackgroundColor]);

    return (
        <>
            <CSSTransition in={parentalControl} classNames="fade" timeout={300} unmountOnExit={true}>
                <ParentalControl onClose={closeParentalControl} onSuccess={openWebsiteAndCloseParentalControl} />
            </CSSTransition>
            <CSSTransition in={!parentalControl} classNames="fade" timeout={300} unmountOnExit={true}>
                <HomeLayout>
                    <HomeWebsiteButton onClick={openParentalControl} />
                    <HomeLogo to="/games" />
                    <HomePlayButton to="/games" />
                </HomeLayout>
            </CSSTransition>
        </>
    );
};
