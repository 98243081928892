import React from 'react';

import {styled} from '../../theming/ThemeProvider';
import {Layout} from '../../components/Layout/Layout';

import BackButton from './Files/back_button.png';
import BackButtonActive from './Files/back_button_active.png';

const OpenGameBackButtonImage = styled(Layout)`
    top: 42px;
    left: 24px;
    position: fixed;

    width: 12%;
    height: 12%;
    min-width: 50px;
    min-height: 50px;

    cursor: pointer;
    background-size: contain;
    background-image: url(${BackButton});
    background-repeat: no-repeat;

    opacity: 0;
    animation: appear 0.4s 0.3s cubic-bezier(0.29, 0.4, 0.36, 1) forwards;

    &:active {
        background-image: url(${BackButtonActive});
    }
`;
export const OpenGameBackButton: React.FC = () => {
    const [once, setOnce] = React.useState(true);
    const goBack = React.useCallback(() => {
        if (once) {
            window.history.back();
        }
        setOnce(false);
    }, [once]);
    return <OpenGameBackButtonImage onClick={goBack} />;
};
