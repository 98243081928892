import React from 'react';

import {styled} from '../../theming/ThemeProvider';

const WebsiteButton = styled.a`
    cursor: pointer;
    animation: appear 0.4s cubic-bezier(0.29, 0.4, 0.36, 1);
    transition: transform 0.2s cubic-bezier(0.29, 0.4, 0.36, 1);

    &:active {
        transform: scale(1.1);

        & circle {
            fill: ${props => props.theme.color.red};
            stroke: ${props => props.theme.color.red};
            transition: all 0.2s cubic-bezier(0.29, 0.4, 0.36, 1);
            fill-opacity: 1;
            stroke-opacity: 1;
        }
    }
`;

export const HomeWebsiteButton: React.FC<{onClick: () => void}> = ({onClick}) => {
    return (
        <WebsiteButton onClick={onClick}>
            <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(-936 -189)">
                        <g transform="translate(733 189)">
                            <g transform="translate(203)">
                                <circle strokeOpacity=".7" stroke="#FFF" strokeWidth="2" fill="#533AAA" cx="24" cy="24" r="23" />
                                <g transform="translate(11 11)" fill="#FFF">
                                    <path d="M24.9385 16.2945l-3.394-4.8482h1.6505a.3394.3394 0 0 0 .3088-.198.3393.3393 0 0 0-.0507-.3631l-2.485-2.8995h1.641a.3412.3412 0 0 0 .3036-.186.3405.3405 0 0 0-.0292-.355L17.521.1386A.3399.3399 0 0 0 17.2514 0c-.119.004-.2078.0476-.273.132l-5.6606 7.3055a.34.34 0 0 0 .2686.5482h1.7793l-2.6172 2.8923a.3395.3395 0 0 0-.0585.366.3396.3396 0 0 0 .3108.2023h1.8388l-3.5773 4.841a.3401.3401 0 0 0 .2738.542l6.1966.0001v3.6776H5.6787v-3.1648h1.198a.34.34 0 0 0 .34-.34v-1.2818a.34.34 0 0 0-.34-.34H2.0061a.34.34 0 0 0-.34.34v1.2818a.34.34 0 0 0 .34.34h1.1981v3.1648H.3401c-.188 0-.3401.152-.3401.34v1.7943c0 .188.152.3401.34.3401h24.0956a.34.34 0 0 0 .34-.34V20.847a.34.34 0 0 0-.34-.3401h-5.9721v-3.6776H24.66a.3388.3388 0 0 0 .3013-.183.3392.3392 0 0 0-.0228-.352" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </WebsiteButton>
    );
};
