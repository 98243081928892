import React from 'react';
import MobileDetect from 'mobile-detect';

import {Layout} from '../Layout/Layout';
import {styled} from '../../theming/ThemeProvider';
import {useClientRect} from '../../../utils/react/hooks/useClientRect';

import turnMobile from './Files/turn_mobile.svg';
import turnTablet from './Files/turn_tablet.svg';

const mobileDetect = new MobileDetect(window.navigator.userAgent);
const PortraitRestrictedLayout = styled(Layout)`
    flex: 1;
    display: flex;
    padding: 3rem;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
`;

export const PortraitChecker: React.FC = ({children}) => {
    const [clientRect] = useClientRect();
    if (clientRect && clientRect.height > clientRect.width) {
        return (
            <PortraitRestrictedLayout>
                <img src={mobileDetect.phone() !== null ? turnMobile : turnTablet} alt="" />
            </PortraitRestrictedLayout>
        );
    }
    return children as any;
};
