import React from 'react';

interface ParentalControlKeyProps {
    value: number;
    //
    onClick: (key: number) => void;
}

export const ParentalControlKey: React.FC<ParentalControlKeyProps> = ({value, onClick}) => {
    const click = React.useCallback(() => onClick(value), [value, onClick]);
    return <span onClick={click}>{value}</span>;
};
